import React, { useEffect ,useState} from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const projectId = props?.projectId;
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (!projectId) {
      setError("Please Select a Job");
    }
  }, []);
  
    if (error) {
      return <div className="text-red-500">{error}</div>;
    }

  return (
    <div
      style={{ height: "100vh" }}
      className="overflow-x-hidden w-[50%] mx-auto shadow-lg"
    >
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
      >
        <Viewer
          fileUrl={`https://rooftop.nyc3.digitaloceanspaces.com/contracts/${projectId}.pdf`} // Path to your PDF
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default PdfViewer;
