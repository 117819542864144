import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { Spin } from "antd";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const LaborCost = (props) => {
  const projectId = props?.projectId;
  const setLoadingState = props?.setLoadingState;

  console.log(projectId, "ggg");
  const [laborCostData, setLaborCostData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);
  const [alertMsg, setAlertMsg] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchLaborProfit();
  }, [projectId]);

  const fetchLaborProfit = async () => {
    setLoadingState(true);
    try {
      if (!projectId) {
        throw new Error("Please Select a Job");
      }
      const response = await UserService.getLabourProfit(projectId);
      if (response.data?.success) {
        setLaborCostData(response.data.output);
        console.log("Material profit data:", response.data.output);
      } else {
        throw new Error("Failed to fetch material cost data");
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching material cost data:", error);
    } finally {
      setLoading(false); // Stop loading when data is fetched
      setLoadingState(false);
    }
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };
  
  const updatePrice = async() => {
    if (projectId) {
      try {
        await UserService.updateLaborProfit(projectId);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log(error.response.data.message)
          setAlertMsg(error.response.data.message);
        } else {
          setAlertMsg(error.message);
        }
        setOpen(true);
      }
      fetchLaborProfit();
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }
  if (!laborCostData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 min-h-screen text-gray-100">
      <style>
        {`
          .welcome-text {
            font-family: 'Pacifico', cursive;
          }
        `}
      </style>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <div
        className="container mx-auto p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h1
            className="text-3xl text-black welcome-text"
            style={{ fontFamily: "Times New Roman, serif" }}
          ></h1>
          <Tooltip title="Click to update with the latest price">
            <button
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              onClick={() => updatePrice()}
            >
              Update Price
            </button>
          </Tooltip>
        </div>
        <h2
          className="text-l font-bold text-center text-black"
          style={{ marginTop: "45px", marginBottom: "45px" }}
        >
          Labor Cost
        </h2>
        <div className="overflow-auto max-h-screen mb-8 text-black">
          <table className="table-auto w-full text-left">
            <thead>
              <tr className="border-b border-black-700 exclude-from-pdf" >
                <th className="px-4 py-2 font-bold">Items</th>
                <th className="px-4 py-2 font-bold">Unit Price</th>
                <th className="px-4 py-2 font-bold">Unit</th>
                <th className="px-4 py-2 font-bold">Total</th>
              </tr>
            </thead>
            <tbody>
              {laborCostData.labor_table.map((item, index) => (
                <tr key={index} className="border-b border-black-700">
                  <td className="px-4 py-2 font-bold">{item?.items}</td>
                  <td className="px-4 py-2">${item?.unit_price?.toFixed(2)}</td>
                  <td className="px-4 py-2">{item?.unit}</td>
                  <td className="px-4 py-2">${item?.total?.toFixed(2)}</td>
                </tr>
              ))}
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Labor Totals</td>
                <td className="px-4 py-2" colSpan="2">
                  ${laborCostData.total_labor_cost?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* New Table for Total Square, Job Name, Total Labor Cost, and % of Labor Cost */}
        <div className="overflow-auto max-h-screen text-black">
          <table className="table-auto w-full text-left">
            <tbody>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Total Square on Job</td>
                <td className="px-4 py-2">{laborCostData.total_sq_on_job}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Job Name</td>
                <td className="px-4 py-2">{laborCostData.jon_name}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Total Labor Cost</td>
                <td className="px-4 py-2">
                  ${laborCostData.total_labor_cost?.toFixed(2)}
                </td>
              </tr>
              <tr className="border-b border-black-700 bg-red-100">
                <td className="px-4 py-2 font-bold">% of Labor Cost</td>
                <td className="px-4 py-2">
                  {laborCostData.total_labor_cost_percent !== null
                    ? `${laborCostData.total_labor_cost_percent?.toFixed(2)} %`
                    : "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LaborCost;
