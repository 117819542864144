import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];

const Form = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC7Yh-7TLGnAtIv5Z69YtGIUtsZphdcpeQ",
    libraries,
  });

  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const [formData, setFormData] = useState({
    no_of_ventilation: 1,
    territory: "",
    first_name: "",
    last_name: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
    phone_number: "",
    email: "",
    sales_rep: "",
    retail_or_insurance: "retail",
    contract_amount: "",
    down_payment: "",
    final_payment_due: "",
    payment_method: "",
    total_amount_financed: "",
    shingle_manufacture: "",
    shingle_type: "",
    shingle_color: "",
    additinal_information: "",
    shingle_upgrade: "",
    eidge_vent_upgrade: "",
    contract_signed_dated: "",
    insurance_carrier_name: "",
    claim_number: "",
    policy_number: "",
    roof_surface_area: "",
    total_number_of_squares: "",
    total_squares_being_torn_off: "",
    total_squares_being_installed: "",
    total_parameter_length: "",
    total_ridge_length: "",
    total_hip_length: "",
    total_valley_length: "",
    existing_roofing_material: "",
    existing_drip_edge: "",
    number_of_pipe_boots: "",
    number_of_rain_caps: "",
    number_of_furnace_vents: "",
    acv_check_amount: "",
    deductible_amount: "",
    additional_payments: "",
    depreciation_amount: "",
    insurance_payment_method: "",
    insurance_total_amount_financed: "",
    contract_signed: "",
    pitch_of_roof: "6_12_or_below",
    height_of_roof: "one_story",
    ventilation_type: "turbine",
    ventilation_details: "",
    skylights: "",
    chimneys: 0,
    chimney_material: "brick",
    sections_below_3_12: "",
    exposed_soffit: "",
    layers: "1",
    satellite_dish: "",
    remove_dish: "",
    starter_strip: 0,
    hip_ridge_cap: 0,
    ice_water: 0,
    drip_edge: 0,
    pipe_boots_scope: 0,
    ventilation_scope: 0,
    skylight_flashing: 0,
    step_flashing: 0,
    cornice_returns: 0,
    cornice_strip: 0,
    roof_loading: 0,
    extensive_landscaping: 0,
    emergency_tarping: 0,
    steep_charge: 0,
    two_story_charge: 0,
    detach_reset_gutter: 0,
    siding_manipulation: 0,
    pool_protection: "no",
    additional_notes: " ",
  });

  const [responseMessage, setResponseMessage] = useState(null);

  
  useEffect(() => {
    // Google Maps Autocomplete initialization
    const initAutocomplete = () => {
      const addressInput = document.getElementById("address");
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInput
      );
      autocomplete.setFields(["address_component"]);
    };

    window.onload = initAutocomplete;
  }, []);

  useEffect(() => {
    if (isLoaded && autocompleteRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "us" }, // Adjust as needed
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setFormData({ ...formData, street_address: place.formatted_address });
        }
      });
    }
  }, [isLoaded]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name ", name);
    console.log("value ", value);
    setFormData({ ...formData, [name]: value });
  };




  const handleProjectTypeChange = (e) => {
    const projectType = e.target.value;
    setFormData({ ...formData, retail_or_insurance: projectType });
  };

  const handleSatelliteDishChange = (e) => {
    const satelliteDish = e.target.value;
    setFormData({ ...formData, satellite_dish: satelliteDish });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        console.log("form data==>", formData);
        const response = await UserService.createProjectForm(formData);
        if (response.status === 200) {
          setResponseMessage("Form submitted successfully");
          navigate("/profile");
        } else {
          setResponseMessage("Form submission failed");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setResponseMessage("Error submitting form");
      }
    } else {
      console.log("not validated");
      setResponseMessage("Please fill out all required fields correctly.");
    }
  };

  const validateForm = () => {
    // Validate required fields, amounts, and dates
    const requiredFields = [
      "territory",
      "first_name",
      "last_name",
      "street_address",
      "city",
      "state",
      "zip",
      "phone_number",
      "email",
      "sales_rep",
      "shingle_manufacture",
      "shingle_type",
      "shingle_color",
      "contract_signed_dated",
      "contract_signed",
      "pool_protection",
    ];

    for (let field of requiredFields) {
      if (!formData[field]) {
        console.log("empty field ", field);
        return false;
      }
    }

    // Validate amounts
    const amountFields = [
      "contract_amount",
      "down_payment",
      "final_payment_due",
      "total_amount_financed",
      "acv_check_amount",
      "deductible_amount",
      "additional_payments",
      "depreciation_amount",
      "insurance_total_amount_financed",
      "roof_surface_area",
      "total_number_of_squares",
      "total_squares_being_torn_off",
      "total_squares_being_installed",
      "total_parameter_length",
      "total_ridge_length",
      "total_hip_length",
      "total_valley_length",
      "number_of_pipe_boots",
      "number_of_rain_caps",
      "number_of_furnace_vents",
      "roof_loading",
      "extensive_landscaping",
      "emergency_tarping",
      "steep_charge",
      "two_story_charge",
      "detach_reset_gutter",
      "siding_manipulation",
    ];

    for (let field of amountFields) {
      if (formData[field] && (isNaN(formData[field]) || formData[field] < 0)) {
        console.log("empty field ", field);
        return false;
      }
    }

    // Validate dates
    if (!formData.contract_signed_dated) {
      return false;
    }

    return true;
  };

  const shingleTypes = {
    owens_corning: ["Supreme", "Oakridge", "Duration", "Duration Storm"],
    iko: ["Dynasty", "Cambridge"],
    tamko: ["Heritage", "Titan XT"],
    gaf: ["Timberline HDZ"],
    atlas: ["ProLam", "Pinnacle Pristine"],
    certainteed: ["Landmark"],
    metal_roofing: [
      "Corrugated Metal Roofing",
      "Standing Seam Metal Roofing",
      "Metal Shingle Roofing",
    ],
    other: ["Ultra-Premium Shingle"],
  };

  useEffect(() => {
    // Reset shingle_type when shingle_manufacture changes
    setFormData((prevFormData) => ({
      ...prevFormData,
      shingle_type: "",
    }));
  }, [formData.shingle_manufacture]);

  
  return (
    <div>
      <style>
        {`
      .welcome-text {
        font-family: 'Pacifico', cursive;
      }
    `}
      </style>
      <form
        onSubmit={handleFormSubmit}
        className="max-w-4xl mx-auto  p-8 rounded-lg "
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <style>
          {`
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="date"],
      select,
      textarea {
        transition: all 0.3s ease;
        padding: 7px;
        border: 1px solid #ddd;
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      input[type="text"]:focus,
      input[type="email"]:focus,
      input[type="number"]:focus,
      input[type="date"]:focus,
      select:focus,
      textarea:focus {
        outline: none;
        border-color: #007BFF;
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
      }

      .form-radio:checked {
        background-color: #007BFF;
        border-color: #007BFF;
      }
    `}
        </style>
        <h1 className="text-2xl font-bold mb-8 text-center welcome-text">
          Roofing Project Form
        </h1>

        {responseMessage && (
          <div className="bg-green-100 text-green-700 p-4 mb-4 rounded">
            {responseMessage}
          </div>
        )}

        <div className="mb-6">
          <label htmlFor="territory" className="block text-gray-700">
            Territory
          </label>
          <select
            id="territory"
            name="territory"
            value={formData.territory}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Territory</option>
            <option value="little_rock">Little Rock</option>
            <option value="fayetteville">Fayetteville</option>
            <option value="jonesboro">Jonesboro</option>
            <option value="hot_springs">Hot Springs</option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="first_name" className="block text-gray-700">
            First Name
          </label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="last_name" className="block text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          {/* <label htmlFor="street_address" className="block text-gray-700">
            Street Address
          </label>
          <input
            type="text"
            id="street_address"
            name="street_address"
            value={formData.street_address}
            onChange={handleInputChange}
            ref = {autocompleteRef}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          /> */}
          <label htmlFor="street_address" className="block text-gray-700">
            Street Address
          </label>
     
           <Autocomplete
              apiKey="AIzaSyC7Yh-7TLGnAtIv5Z69YtGIUtsZphdcpeQ"
              name="street_address"
              value={formData.street_address}
              onChange={handleInputChange}
              onPlaceSelected={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm p-2"

            />
        </div>

        <div className="flex gap-4 mb-6">
          <div className="w-1/3">
            <label htmlFor="city" className="block text-gray-700">
              City
            </label>
            {/* <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
            /> */}
            <Autocomplete
              apiKey="AIzaSyC7Yh-7TLGnAtIv5Z69YtGIUtsZphdcpeQ"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              onPlaceSelected={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm p-2"

            />
          </div>
          <div className="w-1/3">
            <label htmlFor="state" className="block text-gray-700">
              State
            </label>
            {/* <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
            /> */}
                   <Autocomplete
              apiKey="AIzaSyC7Yh-7TLGnAtIv5Z69YtGIUtsZphdcpeQ"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              onPlaceSelected={handleInputChange}
              options = {{types:['(regions)']}}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <div className="w-1/3">
            <label htmlFor="zip" className="block text-gray-700">
              Zip
            </label>
            <input
              type="text"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="phone_number" className="block text-gray-700">
            Phone Number
          </label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="email" className="block text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="sales_rep" className="block text-gray-700">
            Sales Rep
          </label>
          <input
            type="text"
            id="sales_rep"
            name="sales_rep"
            value={formData.sales_rep}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="retail_or_insurance" className="block text-gray-700">
            Retail or Insurance?
          </label>
          <select
            id="retail_or_insurance"
            name="retail_or_insurance"
            value={formData.retail_or_insurance}
            onChange={handleProjectTypeChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="retail">Retail</option>
            <option value="insurance">Insurance</option>
          </select>
        </div>

        {formData.retail_or_insurance === "retail" && (
          <div id="retail_section">
            <div className="mb-6">
              <label htmlFor="contract_amount" className="block text-gray-700">
                Total Contract Amount
              </label>
              <input
                type="text"
                id="contract_amount"
                name="contract_amount"
                value={formData.contract_amount}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="down_payment" className="block text-gray-700">
                Down Payment
              </label>
              <input
                type="text"
                id="down_payment"
                name="down_payment"
                value={formData.down_payment}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="final_payment_due"
                className="block text-gray-700"
              >
                Final Payment Due
              </label>
              <input
                type="text"
                id="final_payment_due"
                name="final_payment_due"
                value={formData.final_payment_due}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="payment_method" className="block text-gray-700">
                Payment Method
              </label>
              <select
                id="payment_method"
                name="payment_method"
                value={formData.payment_method}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              >
                <option value="cash">Cash</option>
                <option value="check">Check</option>
                <option value="credit_card">Credit Card</option>
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="total_amount_financed"
                className="block text-gray-700"
              >
                Total Amount Financed (if any)
              </label>
              <input
                type="text"
                id="total_amount_financed"
                name="total_amount_financed"
                value={formData.total_amount_financed}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>
          </div>
        )}

        {formData.retail_or_insurance === "insurance" && (
          <div id="insurance_section">
            <div className="mb-6">
              <label htmlFor="contract_amount" className="block text-gray-700">
                Total Contract Amount
              </label>
              <input
                type="text"
                id="contract_amount"
                name="contract_amount"
                value={formData.contract_amount}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="acv_check_amount" className="block text-gray-700">
                ACV Check Amount
              </label>
              <input
                type="text"
                id="acv_check_amount"
                name="acv_check_amount"
                value={formData.acv_check_amount}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="deductible_amount"
                className="block text-gray-700"
              >
                Deductible Amount
              </label>
              <input
                type="text"
                id="deductible_amount"
                name="deductible_amount"
                value={formData.deductible_amount}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="additional_payments"
                className="block text-gray-700"
              >
                Additional Payments
              </label>
              <input
                type="text"
                id="additional_payments"
                name="additional_payments"
                value={formData.additional_payments}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="depreciation_amount"
                className="block text-gray-700"
              >
                Depreciation Amount
              </label>
              <input
                type="text"
                id="depreciation_amount"
                name="depreciation_amount"
                value={formData.depreciation_amount}
                onChange={handleInputChange}
                required
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="insurance_payment_method"
                className="block text-gray-700"
              >
                Payment Method
              </label>
              <select
                id="insurance_payment_method"
                name="insurance_payment_method"
                value={formData.insurance_payment_method}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              >
                <option value="cash">Cash</option>
                <option value="check">Check</option>
                <option value="credit_card">Credit Card</option>
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="insurance_total_amount_financed"
                className="block text-gray-700"
              >
                Total Amount Financed (if any)
              </label>
              <input
                type="text"
                id="insurance_total_amount_financed"
                name="insurance_total_amount_financed"
                value={formData.insurance_total_amount_financed}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,2})?$"
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
              />
            </div>
          </div>
        )}

        <div className="mb-6">
          <label htmlFor="shingle_manufacture" className="block text-gray-700">
            Shingle Manufacture
          </label>
          <select
            id="shingle_manufacture"
            name="shingle_manufacture"
            value={formData.shingle_manufacture}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Shingle Manufacture</option>
            <option value="owens_corning">Owens Corning</option>
            <option value="iko">IKO</option>
            <option value="atlas">Atlas</option>
            <option value="certainteed">Certainteed</option>
            <option value="tamko">Tamko</option>
            <option value="gaf">GAF</option>
            <option value="metal_roofing">Metal Roofing</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="shingle_type" className="block text-gray-700">
            Shingle Type
          </label>
          <select
            id="shingle_type"
            name="shingle_type"
            value={formData.shingle_type}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="">Select Shingle Type</option>
            {shingleTypes[formData.shingle_manufacture]?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-6">
          <label htmlFor="shingle_color" className="block text-gray-700">
            Shingle Color
          </label>
          <input
            type="text"
            id="shingle_color"
            name="shingle_color"
            value={formData.shingle_color}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="additinal_information"
            className="block text-gray-700"
          >
            Notes or Additional Information
          </label>
          <textarea
            id="additinal_information"
            name="additinal_information"
            value={formData.additinal_information}
            onChange={handleInputChange}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          ></textarea>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Shingle Upgrade?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="shingle_upgrade"
                value="yes"
                checked={formData.shingle_upgrade === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="shingle_upgrade"
                value="no"
                checked={formData.shingle_upgrade === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Ridge Vent Upgrade?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="eidge_vent_upgrade"
                value="yes"
                checked={formData.eidge_vent_upgrade === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="eidge_vent_upgrade"
                value="no"
                checked={formData.eidge_vent_upgrade === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">
            Was the contract signed and dated?
          </label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="contract_signed"
                value="yes"
                checked={formData.contract_signed === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="contract_signed"
                value="no"
                checked={formData.contract_signed === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label
            htmlFor="contract_signed_dated"
            className="block text-gray-700"
          >
            Date Contract Signed
          </label>
          <input
            type="date"
            id="contract_signed_dated"
            name="contract_signed_dated"
            value={formData.contract_signed_dated}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <h2 className="text-xl font-semibold mb-4 text-gray-800">
          Scope Information
        </h2>

        <div className="mb-6">
          <label
            htmlFor="insurance_carrier_name"
            className="block text-gray-700"
          >
            Insurance Carrier Name
          </label>
          <input
            type="text"
            id="insurance_carrier_name"
            name="insurance_carrier_name"
            value={formData.insurance_carrier_name}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="claim_number" className="block text-gray-700">
            Claim Number
          </label>
          <input
            type="text"
            id="claim_number"
            name="claim_number"
            value={formData.claim_number}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="policy_number" className="block text-gray-700">
            Policy Number
          </label>
          <input
            type="text"
            id="policy_number"
            name="policy_number"
            value={formData.policy_number}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="roof_surface_area" className="block text-gray-700">
            Roof Surface Area
          </label>
          <input
            type="text"
            id="roof_surface_area"
            name="roof_surface_area"
            value={formData.roof_surface_area}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="total_number_of_squares"
            className="block text-gray-700"
          >
            Total Number of Squares
          </label>
          <input
            type="text"
            id="total_number_of_squares"
            name="total_number_of_squares"
            value={formData.total_number_of_squares}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="total_squares_being_torn_off"
            className="block text-gray-700"
          >
            Total Squares being Torn Off
          </label>
          <input
            type="text"
            id="total_squares_being_torn_off"
            name="total_squares_being_torn_off"
            value={formData.total_squares_being_torn_off}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="total_squares_being_installed"
            className="block text-gray-700"
          >
            Total Squares being Installed
          </label>
          <input
            type="text"
            id="total_squares_being_installed"
            name="total_squares_being_installed"
            value={formData.total_squares_being_installed}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="total_parameter_length"
            className="block text-gray-700"
          >
            Total Parameter Length
          </label>
          <input
            type="text"
            id="total_parameter_length"
            name="total_parameter_length"
            value={formData.total_parameter_length}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="total_ridge_length" className="block text-gray-700">
            Total Ridge Length
          </label>
          <input
            type="text"
            id="total_ridge_length"
            name="total_ridge_length"
            value={formData.total_ridge_length}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="total_hip_length" className="block text-gray-700">
            Total Hip Length
          </label>
          <input
            type="text"
            id="total_hip_length"
            name="total_hip_length"
            value={formData.total_hip_length}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="total_valley_length" className="block text-gray-700">
            Total Valley Length
          </label>
          <input
            type="text"
            id="total_valley_length"
            name="total_valley_length"
            value={formData.total_valley_length}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="existing_roofing_material"
            className="block text-gray-700"
          >
            Existing Roofing Material
          </label>
          <select
            id="existing_roofing_material"
            name="existing_roofing_material"
            value={formData.existing_roofing_material}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="3_tab">3-Tab</option>
            <option value="architectural">Architectural</option>
            <option value="metal">Metal</option>
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Existing Drip Edge?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="existing_drip_edge"
                value="yes"
                checked={formData.existing_drip_edge === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="existing_drip_edge"
                value="no"
                checked={formData.existing_drip_edge === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="number_of_pipe_boots" className="block text-gray-700">
            How Many Pipe boots?
          </label>
          <input
            type="number"
            id="number_of_pipe_boots"
            name="number_of_pipe_boots"
            value={formData.number_of_pipe_boots}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="number_of_rain_caps" className="block text-gray-700">
            How many rain caps?
          </label>
          <input
            type="number"
            id="number_of_rain_caps"
            name="number_of_rain_caps"
            value={formData.number_of_rain_caps}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="number_of_furnace_vents"
            className="block text-gray-700"
          >
            How many furnace vents?
          </label>
          <input
            type="number"
            id="number_of_furnace_vents"
            name="number_of_furnace_vents"
            value={formData.number_of_furnace_vents}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <h2 className="text-xl font-semibold mb-4 text-gray-800">
          Roof Information
        </h2>
        <div className="mb-6">
          <label htmlFor="pitch_of_roof" className="block text-gray-700">
            Pitch of Roof
          </label>
          <select
            id="pitch_of_roof"
            name="pitch_of_roof"
            value={formData.pitch_of_roof}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="6_12_or_below">6/12 or Below (default)</option>
            <option value="7_12_to_9_12">7/12-9/12</option>
            <option value="10_12_to_11_12">10/12-11/12</option>
            <option value="12_12">12/12</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="height_of_roof" className="block text-gray-700">
            Height of Roof
          </label>
          <select
            id="height_of_roof"
            name="height_of_roof"
            value={formData.height_of_roof}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="one_story">One Story (default)</option>
            <option value="two_story">Two Story</option>
            <option value="three_story">Three Story</option>
          </select>
        </div>

        <div className="mb-6">
          <label htmlFor="ventilation_type" className="block text-gray-700">
            Type of Ventilation
          </label>
          <select
            id="ventilation_type"
            name="ventilation_type"
            value={formData.ventilation_type}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="turbine">Turbine Vent</option>
            <option value="turtle_box">Turtle/Box Vent</option>
            <option value="ridge">Ridge Vent</option>
            <option value="other">Other</option>
          </select>
        </div>

        {(formData.ventilation_type === "turbine" ||
          formData.ventilation_type === "turtle_box" ||
          formData.ventilation_type === "ridge") && (
          <div className="mb-6">
            <label htmlFor="no_of_ventilation" className="block text-gray-700">
              {formData.ventilation_type === "turbine"
                ? "Number of Turbine"
                : formData.ventilation_type === "turtle_box"
                ? "Number of Turtle/Box Vent"
                : formData.ventilation_type === "ridge"
                ? "How many feet of ridge vent"
                : ""}
            </label>
            <input
              type="number"
              id="no_of_ventilation"
              name="no_of_ventilation"
              value={formData.no_of_ventilation}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        )}
        {/* <div className="mb-6">
        <label htmlFor="no_of_ventilation" className="block text-gray-700">Number of Turbine</label>
        <input type="number" id="no_of_ventilation" name="no_of_ventilation" value={formData.no_of_turbines} onChange={handleInputChange} required className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm" />
      </div> */}

        <div className="mb-6">
          <label htmlFor="ventilation_details" className="block text-gray-700">
            If Other, specify details (with units if necessary)
          </label>
          <input
            type="text"
            id="ventilation_details"
            name="ventilation_details"
            value={formData.ventilation_details}
            onChange={handleInputChange}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Any Skylights?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="skylights"
                value="yes"
                checked={formData.skylights === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="skylights"
                value="no"
                checked={formData.skylights === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="chimneys" className="block text-gray-700">
            Number of Chimneys
          </label>
          <input
            type="number"
            id="chimneys"
            name="chimneys"
            value={formData.chimneys}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="chimney_material" className="block text-gray-700">
            Material on Chimney
          </label>
          <select
            id="chimney_material"
            name="chimney_material"
            value={formData.chimney_material}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="brick">Brick</option>
            <option value="stucco">Stucco</option>
            <option value="siding">Siding</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">
            Any Sections below 3/12?
          </label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="sections_below_3_12"
                value="yes"
                checked={formData.sections_below_3_12 === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="sections_below_3_12"
                value="no"
                checked={formData.sections_below_3_12 === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Any Exposed Soffit?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="exposed_soffit"
                value="yes"
                checked={formData.exposed_soffit === "yes"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="exposed_soffit"
                value="no"
                checked={formData.exposed_soffit === "no"}
                onChange={handleInputChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div className="mb-6">
          <label htmlFor="layers" className="block text-gray-700">
            Number of Layers
          </label>
          <select
            id="layers"
            name="layers"
            value={formData.layers}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        <div className="mb-6">
          <label className="block text-gray-700">Satellite Dish?</label>
          <div className="mt-2 flex items-center">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="satellite_dish"
                value="yes"
                checked={formData.satellite_dish === "yes"}
                onChange={handleSatelliteDishChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="satellite_dish"
                value="no"
                checked={formData.satellite_dish === "no"}
                onChange={handleSatelliteDishChange}
                required
                className="form-radio text-indigo-600"
              />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        {formData.satellite_dish === "yes" && (
          <div id="remove_dish_section" className="mb-6">
            <label className="block text-gray-700">
              If yes, are we removing the dish?
            </label>
            <div className="mt-2 flex items-center">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="remove_dish"
                  value="yes"
                  checked={formData.remove_dish === "yes"}
                  onChange={handleInputChange}
                  required
                  className="form-radio text-indigo-600"
                />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="remove_dish"
                  value="no"
                  checked={formData.remove_dish === "no"}
                  onChange={handleInputChange}
                  required
                  className="form-radio text-indigo-600"
                />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        <h2 className="text-xl font-semibold mb-4 text-gray-800">
          Insurance Scope Items
        </h2>
        <div className="mb-6">
          <label htmlFor="starter_strip" className="block text-gray-700">
            Starter Strip
          </label>
          <input
            type="text"
            id="starter_strip"
            name="starter_strip"
            value={formData.starter_strip}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="hip_ridge_cap" className="block text-gray-700">
            Hip/Ridge Cap
          </label>
          <input
            type="text"
            id="hip_ridge_cap"
            name="hip_ridge_cap"
            value={formData.hip_ridge_cap}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="ice_water" className="block text-gray-700">
            Ice/Water
          </label>
          <input
            type="number"
            id="ice_water"
            name="ice_water"
            value={formData.ice_water}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="drip_edge" className="block text-gray-700">
            Drip Edge
          </label>
          <input
            type="text"
            id="drip_edge"
            name="drip_edge"
            value={formData.drip_edge}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="pipe_boots_scope" className="block text-gray-700">
            Pipe Boots
          </label>
          <input
            type="text"
            id="pipe_boots_scope"
            name="pipe_boots_scope"
            value={formData.pipe_boots_scope}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="ventilation_scope" className="block text-gray-700">
            Ventilation
          </label>
          <input
            type="text"
            id="ventilation_scope"
            name="ventilation_scope"
            value={formData.ventilation_scope}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="skylight_flashing" className="block text-gray-700">
            Skylight / Chimney Flashing
          </label>
          <input
            type="text"
            id="skylight_flashing"
            name="skylight_flashing"
            value={formData.skylight_flashing}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="step_flashing" className="block text-gray-700">
            Step Flashing
          </label>
          <input
            type="number"
            id="step_flashing"
            name="step_flashing"
            value={formData.step_flashing}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="cornice_returns" className="block text-gray-700">
            Cornice Returns
          </label>
          <input
            type="text"
            id="cornice_returns"
            name="cornice_returns"
            value={formData.cornice_returns}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="cornice_strip" className="block text-gray-700">
            Cornice Strip
          </label>
          <input
            type="text"
            id="cornice_strip"
            name="cornice_strip"
            value={formData.cornice_strip}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="roof_loading" className="block text-gray-700">
            Roof Loading (Labor Hours)
          </label>
          <input
            type="text"
            id="roof_loading"
            name="roof_loading"
            value={formData.roof_loading}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="extensive_landscaping"
            className="block text-gray-700"
          >
            Extensive Landscaping (Labor Hours)
          </label>
          <input
            type="text"
            id="extensive_landscaping"
            name="extensive_landscaping"
            value={formData.extensive_landscaping}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="emergency_tarping" className="block text-gray-700">
            Emergency Tarping
          </label>
          <input
            type="text"
            id="emergency_tarping"
            name="emergency_tarping"
            value={formData.emergency_tarping}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="steep_charge" className="block text-gray-700">
            Steep Charge
          </label>
          <input
            type="text"
            id="steep_charge"
            name="steep_charge"
            value={formData.steep_charge}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="two_story_charge" className="block text-gray-700">
            Two Story Charge
          </label>
          <input
            type="text"
            id="two_story_charge"
            name="two_story_charge"
            value={formData.two_story_charge}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="detach_reset_gutter" className="block text-gray-700">
            Detach & Reset Gutter Covers
          </label>
          <input
            type="text"
            id="detach_reset_gutter"
            name="detach_reset_gutter"
            value={formData.detach_reset_gutter}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="siding_manipulation" className="block text-gray-700">
            Siding Manipulation (Labor Hours)
          </label>
          <input
            type="text"
            id="siding_manipulation"
            name="siding_manipulation"
            value={formData.siding_manipulation}
            onChange={handleInputChange}
            required
            pattern="^\d*(\.\d{0,2})?$"
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="pool_protection" className="block text-gray-700">
            Pool Protection
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="pool_protection"
              value="yes"
              checked={formData.pool_protection === "yes"}
              onChange={handleInputChange}
              required
              className="form-radio text-indigo-600"
            />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              name="pool_protection"
              value="no"
              checked={formData.pool_protection === "no"}
              onChange={handleInputChange}
              required
              className="form-radio text-indigo-600"
            />
            <span className="ml-2">No</span>
          </label>
        </div>

        <div className="mb-6">
          <label htmlFor="additional_notes" className="block text-gray-700">
            Additional Notes
          </label>
          <textarea
            id="additional_notes"
            name="additional_notes"
            value={formData.additional_notes}
            onChange={handleInputChange}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm"
          ></textarea>
        </div>

        <button
          type="submit"
          className="text-black border border-black-700 font-bold text-black py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          style={{ backgroundColor: "#e01818", color: "#FFFF" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
