// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const currentUser = AuthService.getCurrentUser();
  return currentUser ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default PrivateRoute;
