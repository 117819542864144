import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { Spin, Button } from "antd";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // Import MUI danger icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";

const JobCost = (props) => {
  const projectId = props?.projectId;
  const setLoadingState = props?.setLoadingState;
  const [JobCostData, setJobCostData] = useState(null);
  const [refreshjobData, setRefreshjobData] = useState(false);
  const [refreshsaveData, setRefreshsaveData] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [repsCommission, setRepsCommission] = useState(11);
  const [franchiseFee, setFranchiseFee] = useState(6);
  const [smOverride, setSmOverride] = useState(3);
  const [officeFee, setOfficeFee] = useState(2);

  useEffect(() => {
    const fetchJobProfit = async () => {
      setLoadingState(true);
      try {
        if (!projectId) {
          throw new Error("Please Select a Job");
        }
        const response = await UserService.getJobProfit(projectId);
        if (response.data?.success) {
          setJobCostData(response.data.output);
          setRepsCommission(response.data.output?.reps_commision);
          setOfficeFee(response.data.output?.office_fee);
          setSmOverride(response.data.output?.sm_override);
          setFranchiseFee(response.data.output?.franchise_fee);
          console.log("Job profit data:", response.data.output);
        } else {
          throw new Error("Failed to fetch material cost data");
        }
      } catch (error) {
        setError(error.message);
        console.error("Error fetching material cost data:", error);
      } finally {
        setLoading(false);
        setLoadingState(false);
      }
    };

    fetchJobProfit();
  }, [projectId, refreshjobData]);

  const saveCommision = async (
    repsCommission,
    franchiseFee,
    smOverride,
    officeFee
  ) => {
    setLoadingState(true);
    try {
      if (!projectId) {
        throw new Error("Please Select a Job");
      }
      const response = await UserService.saveCommision({
        project_id: projectId,
        reps_commision: repsCommission,
        franchise_fee: franchiseFee,
        sm_override: smOverride,
        office_fee: officeFee,
      });
      if (response.data?.success) {
        console.log("save successfully:");
        setRefreshjobData((value) => !value);
      } else {
        throw new Error("Failed to fetch material cost data");
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching material cost data:", error);
    } finally {
      setLoading(false);
      setLoadingState(false);
    }
  };
    const increment = (setFunction, type) => {
    console.log("tpye==>", type);
    setFunction((prev) => prev + 1);
    if (type == "Reps Commission") {
      let reps_commision = repsCommission + 1;
      saveCommision(reps_commision, franchiseFee, smOverride, officeFee);
    } else if (type == "Franchise Fee") {
      let franchise_commision = franchiseFee + 1;
      saveCommision(repsCommission, franchise_commision, smOverride, officeFee);
    } else if (type == "SM Override") {
      let sm_override = smOverride + 1;
      saveCommision(repsCommission, franchiseFee, sm_override, officeFee);
    } else if (type == "Office Fee") {
      let office_fee = officeFee + 1;
      saveCommision(repsCommission, franchiseFee, smOverride, office_fee);
    }
    // saveCommision(repsCommission, franchiseFee, smOverride, officeFee);
  };

  const decrement = (setFunction, type) => {
    setFunction((prev) => (prev > 0 ? prev - 1 : 0));
    if (type == "Reps Commission") {
      let reps_commision = repsCommission > 0 ? repsCommission - 1 : 0;
      saveCommision(reps_commision, franchiseFee, smOverride, officeFee);
    } else if (type == "Franchise Fee") {
      let franchise_commision = franchiseFee > 0 ? franchiseFee - 1 : 0;
      saveCommision(repsCommission, franchise_commision, smOverride, officeFee);
    } else if (type == "SM Override") {
      let sm_override = smOverride > 0 ? smOverride - 1 : 0;
      saveCommision(repsCommission, franchiseFee, sm_override, officeFee);
    } else if (type == "Office Fee") {
      let office_fee = officeFee > 0 ? officeFee - 1 : 0;
      saveCommision(repsCommission, franchiseFee, smOverride, office_fee);
    }
    // saveCommision(repsCommission, franchiseFee, smOverride, officeFee);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="p-6 min-h-screen text-gray-100">
      <style>
        {`
          .welcome-text {
            font-family: 'Pacifico', cursive;
          }
          .ant-btn {
            padding: 0px 6px;
            line-height: 1;
            font-size: 12px;
          }
        `}
      </style>
      <div
        className="container mx-auto p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <h1
          className="text-3xl text-center mb-4 welcome-text text-black"
          style={{ fontFamily: "Times New Roman, serif" }}
        >
          Best Choice Roofing of Arkansas
        </h1>
        <h2
          className="text-l font-bold text-center text-black"
          style={{ marginTop: "45px", marginBottom: "45px" }}
        >
          Job Costs
        </h2>
        <div className="overflow-auto max-h-screen text-black">
          <table className="table-auto w-full text-left">
            <tbody>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Total Contract Amount</td>
                <td className="px-4 py-2">
                  $ {JobCostData?.total_contract_amount}
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Minus Labor</td>
                <td className="px-4 py-2">$ {JobCostData?.minus_labor_cost}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Minus Material</td>
                <td className="px-4 py-2">
                  $ {JobCostData?.minus_material_cost}
                </td>
              </tr>

              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">
                  Reps Commission
                  <span className="ml-4"></span>
                  <Button
                    size="small"
                    onClick={() =>
                      decrement(setRepsCommission, "Reps Commission")
                    }
                  >
                    -
                  </Button>
                  <span className="mx-2">{Math.round(repsCommission)}%</span>
                  <Button
                    size="small"
                    onClick={() =>
                      increment(setRepsCommission, "Reps Commission")
                    }
                  >
                    +
                  </Button>
                </td>
                <td className="px-4 py-2">
                  $ {JobCostData?.minus_reps_commission}
                </td>
              </tr>

              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">
                  Franchise Fee
                  <span className="ml-4"></span>
                  <Button
                    size="small"
                    onClick={() => decrement(setFranchiseFee, "Franchise Fee")}
                  >
                    -
                  </Button>
                  <span className="mx-2">{Math.round(franchiseFee)}%</span>
                  <Button
                    size="small"
                    onClick={() => increment(setFranchiseFee, "Franchise Fee")}
                  >
                    +
                  </Button>
                </td>
                <td className="px-4 py-2">
                  $ {JobCostData?.minus_franchise_fee}
                </td>
              </tr>

              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">
                  SM Override
                  <span className="mx-2"></span>
                  <Button
                    size="small"
                    onClick={() => decrement(setSmOverride, "SM Override")}
                  >
                    -
                  </Button>
                  <span className="mx-2">{Math.round(smOverride)}%</span>
                  <Button
                    size="small"
                    onClick={() => increment(setSmOverride, "SM Override")}
                  >
                    +
                  </Button>
                </td>
                <td className="px-4 py-2">
                  $ {JobCostData?.minus_sm_override}
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">COGS</td>
                <td className="px-4 py-2">$ {JobCostData?.cogs}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Gross Profit</td>
                <td className="px-4 py-2">$ {JobCostData?.gross_profit}</td>
              </tr>

              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">
                  Office Fee
                  <span className="mx-2"></span>
                  <Button
                    size="small"
                    onClick={() => decrement(setOfficeFee, "Office Fee")}
                  >
                    -
                  </Button>
                  <span className="mx-2">{Math.round(officeFee)}%</span>
                  <Button
                    size="small"
                    onClick={() => increment(setOfficeFee, "Office Fee")}
                  >
                    +
                  </Button>
                </td>
                <td className="px-4 py-2">$ {JobCostData?.minus_office_fee}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Net Profit</td>
                <td className="px-4 py-2">$ {JobCostData?.net_profit}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Gross Margin</td>
                <td className="px-4 py-2">
                  $ {JobCostData?.gross_profit} ({JobCostData?.gross_margin} %)
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Net Margin</td>
                <td className="px-4 py-2">
                  $ {JobCostData?.net_profit} ({JobCostData?.net_margin} %)
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Cost Per Sq (Mat+Labor)</td>
                <td className="px-4 py-2">$ {JobCostData?.cost_per_sq}</td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Total Revenue Per Sq</td>
                <td className="px-4 py-2">$ {JobCostData?.total_rev_per_sq}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="container mx-auto p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          marginTop: "20px", // Add margin-top to create separation
        }}
      >
        {/* <span className="px-4 py-2 font-bold text-black">
         Reps Commission :  $  {JobCostData?.minus_reps_commission}
        </span> */}

        <table className="table-auto w-full text-left">
          <tbody>
            <tr className="border-black-700">
              <td
                className="px-4 py-4 font-bold text-black"
                style={{ paddingLeft: "1rem" }}
              >
                Reps Commission :
              </td>
              <td className="px-4 py-4 text-black">
                $ {JobCostData?.minus_reps_commission}
              </td>
              <td className="px-4 py-4 text-black">{repsCommission} %</td>
            </tr>
            <tr className="border-black-700">
              <td className="px-4 py-2 font-bold text-black">Company :</td>
              <td className="px-4 py-2 text-black">
                $ {JobCostData?.net_profit}
              </td>
              <td className="px-4 py-2 text-black">
                {JobCostData?.net_margin} %
                {JobCostData?.net_margin < 20 && (
                  <Tooltip title="Net profit is less than 20 %">
                    <ErrorOutlineIcon style={{ color: "red", marginLeft: 8,marginBottom:8 }} />{" "}
                    {/* MUI Danger Icon */}
                  </Tooltip>
                )}
                {JobCostData?.net_margin > 24 && (
                  <Tooltip title="This is really a good job">
                    <CheckCircleIcon
                      style={{ color: "green", marginLeft: 8,marginBottom:8 }}
                    />{" "}
                    {/* MUI Danger Icon */}
                  </Tooltip>
                )}
                {JobCostData?.net_margin > 20 &&
                  JobCostData?.net_margin < 24 && (
                    <Tooltip title="Not a good net profit">
                      <WarningIcon style={{ color: "yellow", marginLeft: 8,marginBottom:8 }} />{" "}
                      {/* MUI Danger Icon */}
                    </Tooltip>
                  )}
              </td>
            </tr>
            <tr className="border-black-700">
              <td className="px-4 py-2 font-bold text-black">CORP :</td>
              <td className="px-4 py-2 text-black">
                $ {JobCostData?.minus_franchise_fee}
              </td>
              <td className="px-4 py-2 text-black">{franchiseFee} %</td>
            </tr>
            <tr className="border-black-700">
              <td className="px-4 py-2 font-bold text-black">Intall :</td>
              <td className="px-4 py-2 text-black">
                $ {JobCostData?.minus_labor_cost}
              </td>
              <td className="px-4 py-2 text-black">
                {JobCostData?.labor_percent} %
              </td>
            </tr>
            <tr className="border-black-700">
              <td className="px-4 py-2 font-bold text-black">Supply House :</td>
              <td className="px-4 py-2 text-black">
                $ {JobCostData?.minus_material_cost}
              </td>
              <td className="px-4 py-2 text-black">
                {JobCostData?.percent_material_cost} %
              </td>
            </tr>
          </tbody>
        </table>
       { Number(JobCostData?.net_profit) < Number(JobCostData?.minus_reps_commission) && (
        <div style={{"color":"red" , "fontWeight":"bold"}}>Company net profit is lower than the Reps Commission</div>
        )}
      </div>
    </div>
  );
};

export default JobCost;
