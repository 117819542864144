import React, { useState, useRef } from "react";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import MaterialOrderForm from "./MaterialOrder";
import MaterialCost from "./MaterialCost";
import LaborCost from "./LaborCost";
import JobCost from "./JobCost";
import Packet from "./Packet";
import Upgrades from "./Upgrades";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";


const TabPanel = (props) => {
  const { children, value, index, panelRef, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      ref={panelRef}
      {...other}
      style={{
        height: "calc(100vh - 100px)", // Adjust height as needed
        overflowY: "auto", // Enable scrolling inside the tab panel
        padding: "16px",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const Profitability = () => {
  const [value, setValue] = useState(0);
  const [projectId, setProjectId] = useState("");
  const [slelectedJob, setSlelectedJob] = useState("");
  const [loading, setLoading] = useState(false);
  const panelRefs = [useRef(), useRef(), useRef(), useRef(), useRef(),useRef()];
  const [loadingStates, setLoadingStates] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [selectedJobName, setSelectedJobName] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleJobNameChange = (name) => {
    setSelectedJobName(name);
  };

  const handleDownload = async () => {
    setLoading(true);

    const doc = new jsPDF("p", "pt", "a4"); // Use 'a4' for a standard page size
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;
    let startY = margin;

    const getHeadersForSection = (sectionIndex) => {
      switch (sectionIndex) {
        case 0:
          return [["Materials", "Values"]];
        case 1:
          return [["Brand", "Type", " Unit Price", "Unit", "Total"]];
        case 2:
          return [["Items", "Unit Price", "Unit", "Total"]]; // Headers for Labor Cost
        case 3:
          return [["Job", "Cost"]];
        default:
          return [];
      }
    };
    const getTitleForSection = (sectionIndex) => {
      switch (sectionIndex) {
        case 0:
          return "Material Order Form";
        case 1:
          return "Material Cost";
        case 2:
          return "Labor Cost";
        case 3:
          return "Job Cost";
        default:
          return "";
      }
    };
    const tabs_index = [3,0,1,2]
    for (const i of tabs_index) {
      setValue(i);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for content to render

      // Wait for the loading state of the current tab to complete
      while (loadingStates[i]) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Extract data from the panel
      const element = panelRefs[i].current;
      if (!element) continue;

      const headers = element.querySelectorAll("h1, h2");
      headers.forEach((header) => header.remove());

      const rowsToRemove = element.querySelectorAll(".exclude-from-pdf"); // Adjust selector as needed
      rowsToRemove.forEach((row) => row.remove());

      const textContent = element.innerText || element.textContent;

      // Prepare data for the table
      const lines = textContent.split("\n");
      let rows = lines.map((line) => line.split("\t"));
      // const slelected_job = 'Josie Fletcher'
      console.log("slelected_job===>", slelectedJob);
      let job_name_index = -1;
      let job_index = -1;
      let count = 0;
      rows = rows.map((row, index) => {
        if (row[0] === "Job Name:") {
          job_index = index;
          job_name_index = index;
          row[1] = slelectedJob;
        }
        if (row[0] === "Job Address:") {
          job_name_index = -1;
        }

        if (job_name_index >= 0) {
          count = count + 1;
        }
        return row;
      });

      rows.splice(job_index + 1, count - 1);
      rows = rows.filter((row) => row[0] !== "Update Price");
      rows = rows.filter((row) => row[0] !== "DOWNLOAD");
      console.log("rows==>", rows);
      // Get headers for the current section
      const sectionHeaders = getHeadersForSection(i);
      const title = getTitleForSection(i);
      const titleWidth = doc.getTextWidth(title);
      const centerX = (pageWidth - titleWidth) / 2;
      // Add title for each section
      doc.setFontSize(12);
      doc.text(title, centerX, startY);
      startY += 20; // Adjust for title spacing

      // Draw table using autoTable
      autoTable(doc, {
        startY: startY,
        head: sectionHeaders,
        body: rows,
        headStyles: { fillColor: [255, 0, 0], halign: 'center' }, // RGB color for red
        theme: "grid",
        styles: { font: "helvetica", fontSize: 10, cellPadding: 5 ,halign: 'center' },
      });

      // Update startY for the next table
      startY = doc.lastAutoTable.finalY + 20;

      // Check if we need to add a new page
      if (startY > doc.internal.pageSize.height - margin) {
        doc.addPage();
        startY = margin; // Reset to top of the new page
      }
    }

    doc.save("profitability_report.pdf");
    setLoading(false);
    // Download logic remains the same
  };

  const handleLoadingStateChange = (index, isLoading) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = isLoading;
      return newStates;
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{
            position: "fixed",
            top: 80,
            zIndex: 1000,
            backgroundColor: "#ffffff", // Set background color to white
            width: "100%", // Ensure tabs take the full width
            borderBottom: "1px solid #e0e0e0", // Add a bottom border for separation
          }}
        >
          <Tab label="Material Order Form" {...a11yProps(0)} />
          <Tab label="Material Cost" {...a11yProps(1)} />
          <Tab label="Labor Cost" {...a11yProps(2)} />
          <Tab label="Job Cost" {...a11yProps(3)} />
          <Tab label="Packet" {...a11yProps(4)} />
          <Tab label="Upgrades" {...a11yProps(4)} />
        </Tabs>

      </div>
      <div style={{ marginTop: "48px" /* Adjust as needed to account for fixed tabs */ }}>
        {panelRefs.map((panelRef, index) => (
          <TabPanel key={index} value={value} index={index} panelRef={panelRef}>
            {index === 0 && (
              <MaterialOrderForm
                projectId={projectId}
                setProjectId={setProjectId}
                onJobNameChange={handleJobNameChange}
                setSlelectedJob={setSlelectedJob}
                handleDownload={handleDownload}
                loading={loading}
              />
            )}
            {index === 1 && (
              <MaterialCost
                projectId={projectId}
                setLoadingState={(isLoading) =>
                  handleLoadingStateChange(index, isLoading)
                }
              />
            )}
            {index === 2 && (
              <LaborCost
                projectId={projectId}
                setLoadingState={(isLoading) =>
                  handleLoadingStateChange(index, isLoading)
                }
              />
            )}
            {index === 3 && (
              <JobCost
                projectId={projectId}
                setLoadingState={(isLoading) =>
                  handleLoadingStateChange(index, isLoading)
                }
              />
            )}
            {index === 4 && <Packet
             projectId={projectId}
            />} {/* Render the Packet component */}
              {index === 5 && <Upgrades
             projectId={projectId}
            />} {/* Render the Packet component */}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default Profitability;
