import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LoginStyles.css"; // Import your external CSS here

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block text-white">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const onChangeUsername = (e) => setUsername(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
          navigate("/profile");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 10000,
    arrows: false,
  };

  return (
    <div className="loginflexContainer">
      <div className="hidden md:block md:w-1/2 sliderContainer">
        <Slider {...settings}>
          <div>
            <img
              src="/roofingImg1.jpeg"
              alt="Image 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <img
              src="/roofingImg3.jpeg"
              alt="Image 3"
              className="w-full h-full object-cover"
            />
          </div>
        </Slider>
      </div>
      <div
        className="w-full md:w-1/2 h-full flex items-center justify-center loginContainer"
        style={{ backgroundColor: "#F6F5F5", marginBottom: "5em" }}
      >
        <div
          className="w-full max-w-md p-8 rounded-lg formContainer"
          style={{
            background: " rgb(224,24,24)",
            background:
              " linear-gradient(169deg, rgba(224,24,24,0.8436624649859944) 34%, rgba(230,150,150,1) 99%)",
              boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="text-center mb-6">
            <img
              src="/BCR-LOGO-ALLWHITE-2023.png"
              alt="Logo"
              className="w-24 h-24 mx-auto mb-4 object-contain"
            />
            <h2 className="text-2xl font-semibold text-white welcome-text">
              Welcome
            </h2>
            <p style={{ color: "white" }}>Sign into your account</p>
          </div>
          <Form onSubmit={handleLogin} ref={form}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-bold mb-2"
                style={{ color: "white" }}
              >
                Username
              </label>
              <Input
                type="text"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block  text-sm font-bold mb-2"
                style={{ color: "white" }}
              >
                Password
              </label>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              {/* <p className="text-xs italic text-gray-400" ><a href="#" className="text-blue-400">Forgot Password?</a></p> */}
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className="hover:bg-blue-700  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                style={{
                  backgroundColor: "#F6F5F5",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  color: "#000000",
                }}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Login
              </button>
            </div>
            {message && (
              <div className="form-group mt-4">
                <div className="alert alert-danger text-white" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
