import React, { useState, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Paper,
} from '@mui/material';
import { AddShoppingCart, Delete } from '@mui/icons-material';
import UserService from '../../services/user.service';

const GutterForm = ({ projectId }) => {
  const [guttersLength, setGuttersLength] = useState('');
  const [downspouts, setDownspouts] = useState('');
  const [corners, setCorners] = useState('');
  const [story, setStory] = useState('one');
  const [removeExisting, setRemoveExisting] = useState('no');
  const [totalCost, setTotalCost] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState({});

  useEffect(() => {
    const fetchGutterUpgrade = async () => {
      try {
        if (!projectId) {
          throw new Error('Please Select a Job');
        }
        const gutterResponse = await UserService.listGutterUpgrades(projectId);
        if (gutterResponse.data?.success) {
          setCartItems(gutterResponse.data?.gutterUpgrades);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchGutterUpgrade();
  }, [projectId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert input values to numbers
    const guttersLengthNum = parseFloat(guttersLength);
    const downspoutsNum = parseInt(downspouts, 10);
    const cornersNum = parseInt(corners, 10);

    // Costs Calculation Logic
    const gutterCostPerFoot = 12;
    const downspoutCostOneStory = 75;
    const downspoutCostTwoStory = 150;
    const cornerCost = 25;
    const removeExistingCost = removeExisting === 'yes' ? 5 : 0;
    const leafGuardCostPerFoot = 15;
    const twoStoryAdditionalPerFoot = 5;

    const totalGutterCost = guttersLengthNum * gutterCostPerFoot;
    const totalDownspoutCost = downspoutsNum * (story === 'one' ? downspoutCostOneStory : downspoutCostTwoStory);
    const totalCornerCost = cornersNum * cornerCost;
    const additionalStoryCost = story === 'two' ? guttersLengthNum * twoStoryAdditionalPerFoot : 0;
    const totalRemoveExistingCost = guttersLengthNum * removeExistingCost;
    const totalLeafGuardCost = guttersLengthNum * leafGuardCostPerFoot;

    const totalCostGutters = totalGutterCost + totalDownspoutCost + totalCornerCost + additionalStoryCost + totalRemoveExistingCost;
    const totalCostWithLeafGuards = totalCostGutters + totalLeafGuardCost;

    setTotalCost({
      guttersCost: totalGutterCost,
      downspoutsCost: totalDownspoutCost,
      cornersCost: totalCornerCost,
      storyCost: additionalStoryCost,
      removeExistingCost: totalRemoveExistingCost,
      leafGuardsCost: totalLeafGuardCost,
      totalCostGutters,
      totalCost: totalCostWithLeafGuards,
    });
  };

  const addToCart = async (item) => {
    setLoadingCart((prev) => ({ ...prev, [item.type]: true }));
    try {
      const response = await UserService.saveGutterUpgrades({
        project_id: projectId,
        gutter_upgrades: { name: item.type, price: Number(item.unit_price) },
      });
      if (response.data?.success) {
        setCartItems((prevCart) => [...prevCart, { type: item.type, unit_price: item.unit_price }]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCart((prev) => ({ ...prev, [item.type]: false }));
    }
  };

  const removeFromCart = async (index, upgradeType) => {
    // setCartItems((prevCartItems) => prevCartItems.filter((_, i) => i !== index));
    try {
      const response = await UserService.removeGutterUpgrades({
        project_id: projectId,
        gutter_upgrades: { name: upgradeType },
      });
      if (response.data?.success) {
        setCartItems((prevCartItems) => prevCartItems.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container component="main" sx={{ maxWidth: '600px' }}>
      <Paper sx={{ p: 3, mt: 8 }}>
        <Typography variant="h5" gutterBottom>
          Gutter Installation
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Total Length of Gutters (ft)"
                type="number"
                value={guttersLength}
                onChange={(e) => setGuttersLength(e.target.value)}
                fullWidth
                required
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Total # of Downspouts"
                type="number"
                value={downspouts}
                onChange={(e) => setDownspouts(e.target.value)}
                fullWidth
                required
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Total # of Corners"
                type="number"
                value={corners}
                onChange={(e) => setCorners(e.target.value)}
                fullWidth
                required
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="One or Two Story Home"
                value={story}
                onChange={(e) => setStory(e.target.value)}
                fullWidth
                required
              >
                <MenuItem value="one">One</MenuItem>
                <MenuItem value="two">Two</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Remove Existing?"
                value={removeExisting}
                onChange={(e) => setRemoveExisting(e.target.value)}
                fullWidth
                required
              >
                <MenuItem value="no">No</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Calculate
              </Button>
            </Grid>
          </Grid>
        </form>

        {totalCost && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Cost Breakdown:</Typography>
            <Typography>Total Length of Gutters: ${totalCost.guttersCost.toFixed(2)}</Typography>
            <Typography>Total # of Downspouts: ${totalCost.downspoutsCost.toFixed(2)}</Typography>
            <Typography>Total # of Corners: ${totalCost.cornersCost.toFixed(2)}</Typography>
            <Typography>Additional Cost for Two Story Home: ${totalCost.storyCost.toFixed(2)}</Typography>
            <Typography>Cost for Removing Existing Gutters: ${totalCost.removeExistingCost.toFixed(2)}</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="h6">Total Cost for Gutters: ${totalCost.totalCostGutters.toFixed(2)}</Typography>
              <IconButton
                onClick={() => addToCart({ type: 'Seamless Gutters', unit_price: totalCost.totalCostGutters.toFixed(2) })}
              >
                {loadingCart['Seamless Gutters'] ? <CircularProgress size={20} /> : <AddShoppingCart />}
              </IconButton>
            </Box>

            <Typography>Cost for Leaf Guards: ${totalCost.leafGuardsCost.toFixed(2)}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Typography variant="h6">
                Total Cost for Gutters + Leaf Guards: ${totalCost.totalCost.toFixed(2)}
              </Typography>
              <IconButton
                onClick={() => addToCart({ type: 'Gutters + Leaf Guards', unit_price: totalCost.totalCost.toFixed(2) })}
              >
                {loadingCart['Gutters + Leaf Guards'] ? <CircularProgress size={20} /> : <AddShoppingCart />}
              </IconButton>
            </Box>
          </Box>
        )}

        {cartItems.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Cart Items:</Typography>
            {cartItems.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                <Typography>
                  {item?.type} : ${item?.unit_price}
                </Typography>
                <IconButton onClick={() => removeFromCart(index, item?.type)}>
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default GutterForm;
