import React, { useState } from 'react';
import { Button, Box, Paper, Typography, ButtonGroup } from '@mui/material';
import ShingleUpgrade from './ShingleUpgrade'
import GutterUpgrade from './GutterUpgrades'

const ComponentA = () => {
  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Component A
      </Typography>
      <Typography variant="body1">
        This is the content of Component A.
      </Typography>
    </Paper>
  );
};

const ComponentB = () => {
  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Component B
      </Typography>
      <Typography variant="body1">
        This is the content of Component B.
      </Typography>
    </Paper>
  );
};

const Upgrades = ({projectId}) => {
  const [selectedComponent, setSelectedComponent] = useState('A');

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
      {/* Buttons at the top */}
      <ButtonGroup sx={{ marginBottom: 2 }} variant="contained">
        <Button
          onClick={() => setSelectedComponent('A')}
          sx={{ backgroundColor: selectedComponent === 'A' ? 'red' : 'grey', '&:hover': { backgroundColor: 'darkred' } }}
          // color={selectedComponent === 'A' ? 'primary' : 'inherit'}
        >
          Gutter Upgrades
        </Button>
        <Button
          onClick={() => setSelectedComponent('B')}
          sx={{ backgroundColor: selectedComponent === 'B' ? 'red' : 'grey', '&:hover': { backgroundColor: 'darkred' } }}
          // color={selectedComponent === 'B' ? 'primary' : 'inherit'}
        >
          Shingle Upgrades
        </Button>
      </ButtonGroup>

      {/* Render Components */}
      <Box sx={{ marginTop: 2 }}>
        {selectedComponent === 'A' ? <GutterUpgrade projectId={projectId} /> : <ShingleUpgrade projectId={projectId} />}
      </Box>
    </Box>
  );
};

export default Upgrades;
