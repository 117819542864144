import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
const Navbar = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  return (
    <nav className="fixed top-0 left-0 w-full p-4 flex justify-between items-center" style={{background: "rgb(224,24,24)",
      background:" linear-gradient(169deg, rgba(224,24,24,1) 34%, rgba(230,150,150,1) 99%)"}}>
       <Link to="/profile" className="flex items-center">
        <img src="/BCR-LOGO-ALLWHITE-2023.png" alt="Logo" className="w-12 h-12" />
      </Link>
      <div className="flex items-center space-x-4">
        {showModeratorBoard && (
          <Link to={"/mod"} className="text-white">Moderator Board</Link>
        )}
      </div>
      {currentUser ? (
        <div className="relative">
          <button onClick={toggleDropdown} className="focus:outline-none">
            <FontAwesomeIcon icon={faUser} className="text-white" />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
              <Link to={"/profile"} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                {currentUser.username}
              </Link>
              <a href="/login" className="block px-4 py-2 text-gray-800 hover:bg-gray-200" onClick={logOut}>
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                LogOut
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center space-x-4">
          <Link to={"/login"} className="text-white">Login</Link>
          <Link to={"/register"} className="text-white">Sign Up</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
