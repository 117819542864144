import React, { useState, useEffect } from 'react';
import UserService from "../services/user.service";
import { message, Modal } from 'antd';
import 'antd/dist/antd.css'; // Ensure antd CSS is imported
import {Spin} from 'antd'

const Pricing = () => {
  const [shingles, setShingles] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [labor, setLabor] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [saveType, setSaveType] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);  // Add loading state for save operation

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const Shingledata = await UserService.getShinglePrice();
        const accesoryData = await UserService.getAccessoryPrice();
        const laborData = await UserService.getLaborPrice();
        if (Shingledata.data?.success) {
          setShingles(Shingledata.data?.shingle_prices);
        }
        if (accesoryData.data?.success) {
          setAccessories(accesoryData.data?.accessory_prices);
        }
        if (laborData.data?.success) {
          setLabor(laborData.data?.labor_prices);
        }
      } catch (error) {
        console.error("Error fetching job names:", error);
      }
    };

    fetchPrices();
  }, []);

  const handleShingleChange = (index, field, value) => {
    const newShingles = [...shingles];
    newShingles[index][field] = value;
    setShingles(newShingles);
  };

  const handleAccessoryChange = (index, field, value) => {
    const newAccessories = [...accessories];
    newAccessories[index][field] = value;
    setAccessories(newAccessories);
  };

  const handleLaborChange = (index, field, value) => {
    const newLabor = [...labor];
    newLabor[index][field] = value;
    setLabor(newLabor);
  };

  const savePrices = async () => {
    setSaveLoading(true)
    try {
      if (saveType === 'shingles') {
        await UserService.saveShinglePrices(shingles);
        message.success('Shingle prices saved successfully.');
      } else if (saveType === 'accessories') {
        await UserService.saveAccessoryPrices(accessories);
        message.success('Accessory prices saved successfully.');
      } else if (saveType === 'labor') {
        await UserService.saveLaborPrices(labor);
        message.success('Labor prices saved successfully.');
      }
    } catch (error) {
      console.error(`Error saving ${saveType} prices:`, error);
      message.error(`Error saving ${saveType} prices.`);
    } finally {
      setModalVisible(false);
      setSaveLoading(false)
    }
  };

  const showModal = (type) => {
    console.log("show modal")
    setSaveType(type);
    setModalVisible(true);
    console.log(modalVisible,"modal visible")
  };

  return (
    <div className="p-6 min-h-screen text-gray-100">
      <style>
        {`
          .welcome-text {
            font-family: 'Pacifico', cursive;
          }
            
          
            `}
      </style>
      <div className="container mx-auto p-6 rounded-lg shadow-lg"      style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}>
        <h1 className="text-3xl text-center mb-4 text-black welcome-text" style={{ fontFamily: 'Times New Roman, serif' }}>Best Choice Roofing of Arkansas</h1>
        <h2 className="text-xl font-semibold text-center text-black mb-8"
        style={{ marginTop: "45px", marginBottom: "45px" }}
        >Pricing</h2>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-black mb-4">Shingle Pricing</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-black-700 text-black">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Brand</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Product Line</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Supplier</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Bundle Price</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Per Sq Price</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black-700 text-black">
                {shingles.map((shingle, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{shingle.brand}</td>
                    <td className="px-4 py-2">{shingle.productLine}</td>
                    <td className="px-4 py-2">{shingle.supplier}</td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="w-full border bg-black-100 p-2 rounded text-black"
                        value={shingle.pricePerBundle}
                        onChange={(e) => handleShingleChange(index, 'pricePerBundle', parseFloat(e.target.value))}
                      />
                    </td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="w-full border bg-black-100 p-2 rounded text-black"
                        value={shingle.pricePerSquare}
                        onChange={(e) => handleShingleChange(index, 'pricePerSquare', parseFloat(e.target.value))}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => showModal('shingles')}
                  className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              >
              Save Shingle Prices
            </button>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-black mb-4">Accessories Pricing</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-black-700 text-black">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Length Per Bundle</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Accessory Name</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Supplier</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Packet Price</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Packet Type</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Price Per Unit</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Unit</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black-700">
                {accessories.map((accessory, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{accessory.lengthPerBundle}</td>
                    <td className="px-4 py-2">{accessory.name}</td>
                    <td className="px-4 py-2">{accessory.supplier}</td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="w-full border bg-black-100 p-2 rounded text-black"
                        value={accessory.packetPrice}
                        onChange={(e) => handleAccessoryChange(index, 'packetPrice', parseFloat(e.target.value))}
                      />
                    </td>
                    <td className="px-4 py-2">{accessory.packet}</td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="w-full border bg-black-100 p-2 rounded text-black"
                        value={accessory.pricePerUnit}
                        onChange={(e) => handleAccessoryChange(index, 'pricePerUnit', parseFloat(e.target.value))}
                      />
                    </td>
                    <td className="px-4 py-2">{accessory.unit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => showModal('accessories')}
                 className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                 style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              >
              Save Accessory Prices
            </button>
          </div>
        </div>

        <div className="mb-8">
          <h3 className="text-xl font-semibold text-black mb-4">Labor Pricing</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-black-700 text-black">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Task</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Unit</th>
                  <th className="px-4 py-2 text-left text-xs font-medium text-black-300 uppercase tracking-wider">Price</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black-700">
                {labor.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">{item.task}</td>
                    <td className="px-4 py-2">{item.unit}</td>
                    <td className="px-4 py-2">
                      <input
                        type="number"
                        className="w-full border bg-black-100 p-2 rounded text-black"
                        value={item.price}
                        onChange={(e) => handleLaborChange(index, 'price', parseFloat(e.target.value))}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => showModal('labor')}
                  className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              >
              Save Labor Prices
            </button>
          </div>
        </div>
      </div>

      <Modal
        visible={modalVisible}
        onOk={savePrices}
        onCancel={() => setModalVisible(false)}
        okText="Save"
        cancelText="Cancel"
        className='modal'
        okButtonProps={{
          style: { 
            backgroundColor: '#e01818', 
            color: '#FFFF',
            fontSize:"bold",
            borderColor:"#707070" 
          }
        }}
      >
        {saveLoading?(
                    <div className="flex justify-center items-center">
                    <Spin size="large" />
                  </div>
        
        ):(
          <p>Are you sure you want to save the {saveType} prices?</p>

        )}
      </Modal>
    </div>
  );
};

export default Pricing;
