import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";

const ShingleUpgrade = ({ projectId }) => {
  const [upgrades, setUpgrades] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState([]);
  const [loadingStates, setLoadingStates] = useState({}); // New state for loading

  useEffect(() => {
    // Fetch the shingle upgrade data from the backend
    const fetchShingleUpgrade = async () => {
      try {
        if (!projectId) {
          throw new Error("Please Select a Job");
        }
        const response = await UserService.shingleUpgrade(projectId);
        const shingleResponse = await UserService.listShingleUpgrades(projectId);
        setUpgrades(response.data.data);
        if (shingleResponse.data?.success) {
          setCart(shingleResponse.data?.shingleUpgrades);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchShingleUpgrade();
  }, [projectId]);

  const addToCart = async (upgradeType, cost) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [upgradeType]: true }));

      const response = await UserService.saveShingleUpgrades({
        project_id: projectId,
        shingle_upgrades: { name: upgradeType, price: cost },
      });
      if (response.data?.success) {
        setCart((prevCart) => [
          ...prevCart,
          { type: upgradeType, unit_price: cost },
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [upgradeType]: false }));
    }
  };

  const removeFromCart = async (indexToRemove, upgradeType,price) => {
    try {
      const response = await UserService.removeShingleUpgrades({
        project_id: projectId,
        shingle_upgrades: { name: upgradeType, price },
      });
      if (response.data?.success) {
        setCart((prevCart) =>
          prevCart.filter((_, index) => index !== indexToRemove)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Shingle Upgrade Options
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Upgrade Type</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Add to Cart</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(upgrades).map(([upgradeType, cost]) => (
              <TableRow key={upgradeType}>
                <TableCell>{upgradeType}</TableCell>
                <TableCell>{typeof cost === "string" ? cost : `$${cost.toFixed(2)}`}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => addToCart(upgradeType, cost)}
                    disabled={loadingStates[upgradeType]}
                  >
                    {loadingStates[upgradeType] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <ShoppingCartIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Cart
      </Typography>
      {cart.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Upgrade Type</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Remove from Cart</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    {typeof item.unit_price === "string"
                      ? item.unit_price
                      : `$${item.unit_price}`}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => removeFromCart(index, item.type,item.unit_price)}
                    >
                      <RemoveShoppingCartIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Your cart is empty</Typography>
      )}
    </div>
  );
};

export default ShingleUpgrade;
