import React, { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import Tooltip from "@mui/material/Tooltip";
import { Spin } from "antd";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const MaterialCost = (props) => {
  const projectId = props?.projectId;
  const setLoadingState = props?.setLoadingState;

  console.log(projectId, "fffff");
  const [materialCost, setMaterialCost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alertMsg, setAlertMsg] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchMaterialProfit();
  }, [projectId]);

  const fetchMaterialProfit = async () => {
    setLoadingState(true);

    try {
      if (!projectId) {
        throw new Error("Please Select a Job");
      }
      const response = await UserService.getMaterialProfit(projectId);
      if (response.data?.success) {
        setMaterialCost(response.data.output);
        console.log("Material profit data:", response.data.output);
      } else {
        throw new Error("Failed to fetch material cost data");
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching material cost data:", error);
    } finally {
      setLoading(false);
      setLoadingState(false);
    }
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };


  const updatePrice = async () => {
    if (projectId) {
      try {
        await UserService.updateMaterialProfit(projectId);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.log(error.response.data.message)
          setAlertMsg(error.response.data.message);
        } else {
          setAlertMsg(error.message);
        }
        setOpen(true);
      }
      fetchMaterialProfit();
    }
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!materialCost) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 min-h-screen text-gray-100">
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <style>
        {`
          .welcome-text {
            font-family: 'Pacifico', cursive;
          }
        `}
      </style>
      <div
        className="container mx-auto p-6 rounded-lg shadow-lg"
        style={{
          backgroundColor: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h1
            className="text-3xl text-black welcome-text"
            style={{ fontFamily: "Times New Roman, serif" }}
          ></h1>
          <Tooltip title="Click to update with the latest price">
            <button
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              onClick={() => updatePrice()}
            >
              Update Price
            </button>
          </Tooltip>
        </div>
        <h1
          className="text-l font-bold text-center text-black "
          style={{ marginTop: "45px", marginBottom: "45px" }}
        >
          Material Cost
        </h1>
        <div className="overflow-auto max-h-screen text-black ">
          <table className="table-auto w-full text-left mb-8">
            <tbody>
              <tr className="border-b border-black-700 exclude-from-pdf">
                <td className="px-4 py-2 font-bold">Brand</td>
                <td className="px-4 py-2 font-bold">Type</td>
                <td className="px-4 py-2 font-bold">Unit Price</td>
                <td className="px-4 py-2 font-bold">Units</td>
                <td className="px-4 py-2 font-bold">Total</td>
              </tr>
              {materialCost.table.map((item, index) => (
                <tr key={index} className="border-b border-black-700">
                  <td className="px-4 py-2">{item.brand}</td>
                  <td className="px-4 py-2">{item.type}</td>
                  <td className="px-4 py-2">
                    ${item.unit_price?.toFixed(2) || "0.00"}
                  </td>
                  <td className="px-4 py-2">{item.unit}</td>
                  <td className="px-4 py-2">
                    ${item.total?.toFixed(2) || "0.00"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="table-auto w-full text-left">
            <tbody>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Material Totals</td>
                <td className="px-4 py-2">
                  ${materialCost.material_cost_total?.toFixed(2) || "0.00"}
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Tax (10%)</td>
                <td className="px-4 py-2">
                  ${materialCost.tax?.toFixed(2) || "0.00"}
                </td>
              </tr>
              <tr className="border-b border-black-700">
                <td className="px-4 py-2 font-bold">Material Cost</td>
                <td className="px-4 py-2">
                  ${materialCost.material_cost?.toFixed(2) || "0.00"}
                </td>
              </tr>
              <tr className="border-b border-black-700 bg-red-100">
                <td className="px-4 py-2 font-bold">% of Material Cost</td>
                <td className="px-4 py-2">
                  {materialCost.material_cost_percent?.toFixed(2) || "0.00"}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MaterialCost;
