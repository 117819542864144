import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import UserService from "../services/user.service";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Charts = () => {
  console.log("Charts component is rendered");
  const [jobNames, setJobNames] = useState([]);
  const [projectId, setProjectId] = useState();
  const [barData, setBarData] = useState({
    labels: [     "Labor Cost",
      "Material Cost",
      "Rep's Commission",
      "Franchise Fee",
      "SM Override",
      "Office Fee"
    ],
    datasets: [
      {
        label: "",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });
  const [slelectedJob, setSlelectedJob] = useState();
  useEffect(() => {
    const fetchJobNames = async () => {
      try {
        const data = await UserService.getAllJobNames();
        if (data.data?.success) {
          setJobNames(data.data.job_names);
        }
      } catch (error) {
        console.error("Error fetching job names:", error);
      }
    };

    fetchJobNames();
  }, []);
  const handleJobChange = async(event) => {
    setProjectId(event.target.value);
    const job = jobNames.find((job) => job.id === event.target.value);
    setSlelectedJob(job?.name);
    try{
      const data = await UserService.getCostDistribution(job?.id);
      if (data.data?.success) {
        console.log(data.data?.output);
        setBarData({
          labels: [
            "Labor Cost",
            "Material Cost",
            "Rep's Commission",
            "Franchise Fee",
            "SM Override",
            "Office Fee",
            "Net Profit"
          ],
          datasets: [
            {
              label: "",
              data: [
                parseFloat(data.data?.output?.minus_labor_cost),
                parseFloat(data.data?.output?.minus_material_cost),
                parseFloat(data.data?.output?.minus_reps_commission),
                parseFloat(data.data?.output?.minus_franchise_fee),
                parseFloat(data.data?.output?.minus_sm_override),
                parseFloat(data.data?.output?.minus_office_fee),
                parseFloat(data.data?.output?.net_profit)
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.8)",
                "rgba(54, 162, 235, 0.8)",
                "rgba(255, 206, 86, 0.8)",
                "rgba(75, 192, 192, 0.8)",
                "rgba(153, 102, 255, 0.8)",
                "rgba(255, 159, 64, 0.8)",
                "rgba(100, 255, 100, 0.8)" // Different color for net profit
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(100, 255, 100, 1)" // Different border color for net profit
              ],
              borderWidth: 1
            }
          ]
        })
      }
    }
    catch(error){

    }
  };
  

  const pieData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      className="flex flex-col items-center w-full p-4 text-black min-h-screen maxx-h-screen"
      style={{ backgroundColor: "#F6F5F5" }}
    >
      <style>
        {`
      .welcome-text {
        font-family: 'Pacifico', cursive;
      }
    `}
      </style>
      <h2 className="text-3xl font-bold text-center mb-8">
        Charts
      </h2>
      <select
        onChange={handleJobChange}
        value={projectId}
        className="w-full border p-2 rounded text-black"
        style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <option value="">Select Job</option>
        {jobNames.map((jobName) => (
          <option key={jobName.id} value={jobName.id}>
            {jobName.name}
          </option>
        ))}
      </select>
      <div className="flex flex-col lg:flex-row justify-center gap-8 w-full h-full">
        <div
          className="flex-1 min-w-[300px] lg:max-w-[50%]  p-4 rounded-lg shadow-lg"
          style={{
            backgroundColor: "white",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            marginTop:'10px'
          }}
        >
          <h3 className="text-2xl font-semibold text-center text-black mb-4">
            Cost Distribution
          </h3>
          <div className="h-[400px] text-black">
            <Bar
              data={barData}
              options={{
                maintainAspectRatio: false,
                plugins: { legend: { labels: { color: "black" } } },
                scales: {
                  x: {
                    ticks: { color: "black" },
                    grid: { color: "rgba(255, 255, 255, 0.2)" },
                  },
                  y: {
                    ticks: { color: "black" },
                    grid: { color: "rgba(255, 255, 255, 0.2)" },
                  },
                },
              }}
            />
          </div>
        </div>
        {/* <div
          className="flex-1 min-w-[300px] lg:max-w-[50%] rounded-lg shadow-lg"
          style={{
            backgroundColor: "white",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <h3 className="text-2xl font-semibold text-center text-black mt-4 mb-4 welcome-text">
            Pie Chart
          </h3>
          <div className="h-[400px]">
            <Pie
              data={pieData}
              options={{
                maintainAspectRatio: false,
                plugins: { legend: { labels: { color: "black" } } },
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Charts;
