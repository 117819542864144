import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Form from "./components/Form";
import Profitability from "./components/profitability";
import Charts from "./components/Charts";
import ContractList from "./components/ContractList";
import Pricing from "./components/Pricing";
import PrivateRoute from "./components/PrivateRoute";
// import '../src/components/LoginStyles.css';  // Import your external CSS here
import { Navigate } from "react-router-dom";

const App = () => {
  const location = useLocation();

  // Define the routes where you want to remove the scrollbar
  const noScrollBarRoutes = ["/login", "/register", "/profile"];
  const isNoScrollBarRoute = noScrollBarRoutes.includes(location.pathname);

  return (
    <>
      <div
        className={isNoScrollBarRoute ? "no-scrollbar" : ""}
        style={{
          width: "100vw",
          height: "100vh",
          overflow: isNoScrollBarRoute ? "hidden" : "auto",
          margin: "0",
          padding: "0",
          paddingTop: "80px",


        }}
      >
        <Navbar />
        <div>
          <Routes>
          <Route exact path="/profile" element={<PrivateRoute element={Profile} />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/form" element={<Form />} />
            <Route path="/mof" element={<Profitability />} />
            <Route path="/contractList" element={<ContractList />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="*" element={<Navigate to="/login" />} />

          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
