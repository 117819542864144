import React, { useState, useEffect } from 'react';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { useNavigate } from 'react-router-dom';
import './profileStyle.css';  // Import your external CSS here
import {Spin} from 'antd'
const Profile = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [publicContent, setPublicContent] = useState([]);
  const [loading, setLoading] = useState(true);  // Add loading state

  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }

    // Fetch public content
    UserService.getPublicContent().then(
      response => {
        console.log('Fetched public content:', response.data.forms_list);
        // Add 'Chart' manually if not present
        const contentList = response.data.forms_list;
        setPublicContent(contentList);
        setLoading(false); 

      },
      error => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.error('Error fetching public content:', _content);
        setPublicContent([]);
        setLoading(false);  

      }
    );
  }, []);

  const navigateToForm = () => {
    navigate('/form');
  };

  const navigateToMaterialOrder = () => {
    navigate('/mof');
  };

  const navigateToContractList = () => {
    navigate('/contractList');
  };

  const navigateToCharts = () => {
    navigate('/charts');
  };

  const navigateToPricing = () => {
    navigate('/pricing');
  }

  const renderButton = (content) => {
    switch (content) {
      case 'New Contract':
        return (
          <div className="flex flex-col items-center justify-center bg-gray-100 border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
            <img src="/boy_7641805.png" alt="New Contract" className="w-16 h-16 mb-4" />
            <button
              onClick={navigateToForm}
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              style={{ backgroundColor: "#e01818", color: "#FFFF" }}
            >
              {content}
            </button>
          </div>
        );
      case 'Contract Information':
        return (
          <div className="flex flex-col items-center justify-center bg-gray-100 border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
            <img src="/profile_1000354.png" alt="Contract Information" className="w-16 h-16 mb-4" />
            <button
              onClick={navigateToMaterialOrder}
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              style={{ backgroundColor: "#e01818", color: "#FFFF" }}
            >
              {content}
            </button>
          </div>
        );
        case 'Contract List':
          return (
            <div className="flex flex-col items-center justify-center bg-gray-100 border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <img src="/contract_list.jpeg" alt="Contract List" className="w-16 h-16 mb-4" />
              <button
                onClick={navigateToContractList}
                className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              >
                {content}
              </button>
            </div>
          );
      case 'Chart':
        return (
          <div className="flex flex-col items-center justify-center bg-gray-100 border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
            <img src="/analytics_921591.png" alt="Chart" className="w-16 h-16 mb-4" />
            <button
              onClick={navigateToCharts}
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              style={{ backgroundColor: "#e01818", color: "#FFFF" }}
            >
              {content}
            </button>
          </div>
        );
        case 'pricing':
          return (
            <div className="flex flex-col items-center justify-center bg-gray-100 border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-200">
              <img src="/online-shop_567699.png" alt="Pricing" className="w-16 h-16 mb-4" />
              <button
                onClick={navigateToPricing}
                className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                style={{ backgroundColor: "#e01818", color: "#FFFF" }}
              >
                {content}
              </button>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className="flexContainer">
      <div className="w-full max-w-4xl rounded-lg shadow-lg p-6 profileContainer">
        <header className="flex flex-col items-center pb-4 border-b">
          {currentUser && currentUser.roles.includes('ROLE_ADMIN') ? (
            <h1 className="text-2xl font-bold text-center welcome-text" style={{ fontFamily: 'Times New Roman, serif' }}>Admin Dashboard</h1>
          ) : (
            <h1 className="text-2xl font-bold text-center welcome-text" style={{ fontFamily: 'Times New Roman, serif' }}>Rep Dashboard</h1>
          )}
        </header>
        <main className="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-6">
          {loading ? (
            <div className="flex justify-center items-center col-span-2">
              <Spin size="large" />
            </div>
          ) : (
            publicContent.map((content, index) => (
              <React.Fragment key={index}>
                {renderButton(content)}
              </React.Fragment>
            ))
          )}
        </main>
      </div>
    </div>
  );
};

export default Profile;
