import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LoginStyles.css"; // Use the same CSS for consistent styling

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block text-white">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="invalid-feedback d-block text-white">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="invalid-feedback d-block text-white">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="invalid-feedback d-block text-white">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(username, email, password, ["user"]).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  };

  return (
    <div className="loginflexContainer">
      <div className="hidden md:block md:w-1/2 sliderContainer">
        <Slider {...settings}>
          <div>
            <img
              src="/roofingImg1.jpeg"
              alt="Image 1"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <img
              src="/roofingImg3.jpeg"
              alt="Image 3"
              className="w-full h-full object-cover"
            />
          </div>
        </Slider>
      </div>
      <div
        className="w-full md:w-1/2 h-full flex items-center justify-center loginContainer"
        style={{ backgroundColor: "#F6F5F5", marginBottom: "5em" }}
      >
        <div
          className="w-full max-w-md p-8 rounded-lg formContainer"
          style={{
            background: " rgb(224,24,24)",
            background:
              " linear-gradient(169deg, rgba(224,24,24,0.8436624649859944) 34%, rgba(230,150,150,1) 99%)",
              boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="text-center mb-6">
            <img
              src="/BCR-LOGO-ALLWHITE-2023.png"
              alt="Logo"
              className="w-24 h-24 mx-auto mb-4 object-contain"
            />
            <h2 className="text-2xl font-semibold text-white welcome-text">
              Welcome
            </h2>
            <p style={{ color: "white" }}>Create your account</p>
          </div>
          <Form onSubmit={handleRegister} ref={form}>
            {!successful && (
              <div>
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    style={{ color: "white" }}
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <Input
                    type="text"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    style={{ color: "white" }}
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Input
                    type="text"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    validations={[required, validEmail]}
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-sm font-bold mb-2"
                    style={{ color: "white" }}
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <Input
                    type="password"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="flex items-center justify-center">
                  <button
                    className="hover:bg-blue-700 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    style={{
                      backgroundColor: "#F6F5F5",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      color: "#000000",
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group mt-4">
                <div
                  className={
                    successful
                      ? "alert alert-success text-white"
                      : "alert alert-danger text-white"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
